// store/editor.js
import { defineStore } from 'pinia';

export const useEditorStore = defineStore('editor', {
    state: () => ({
        code: localStorage.getItem('editorCode') || '',
        lastSaveTime: Date.now(),
    }),
    actions: {
        setCode(newCode: string) {
            if (this.code !== newCode) {  // 只在代码发生变化时保存
                this.code = newCode;
                this.lastSaveTime = Date.now();
                localStorage.setItem('editorCode', newCode);
            }
        },
        getLastSaveTime() {
            return this.lastSaveTime;
        }
    },
});
