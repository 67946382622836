<template>
  <t-tooltip content="导出为图片分享">
    <t-button
      variant="outline"
      theme="default"
      @click="shareAsImage"
    >
      <template #icon>
        <ShareIcon />
      </template>
      <span>分享</span>
    </t-button>
  </t-tooltip>

  <!-- 导出元素 -->
  <div id="code-export" class="code-export" ref="exportRef" v-show="isExporting">
    <div class="export-header">
      <img 
        :src="require('@/assets/elogo.jpg')" 
        alt="logo" 
        class="export-logo"
      />
      <span class="export-title">E时代IDE</span>
    </div>
    <div class="export-content">
      <pre><code :class="language">{{ code }}</code></pre>
    </div>
    <div class="export-footer">
      <span class="website">ide.emoera.com</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, nextTick, defineProps } from 'vue';
import { ShareIcon } from 'tdesign-icons-vue-next';
import { MessagePlugin } from 'tdesign-vue-next';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import html2canvas from 'html2canvas';

const props = defineProps<{
  code: string;
  language: string;
}>();

const exportRef = ref<HTMLElement | null>(null);
const isExporting = ref(false);

const shareAsImage = async () => {
  try {
    isExporting.value = true;
    await nextTick();
    
    if (!exportRef.value) {
      throw new Error('导出元素不存在');
    }

    // 先应用代码高亮
    const highlightedCode = hljs.highlight(props.code, {
      language: props.language,
      ignoreIllegals: true
    }).value;

    // 获取代码元素并更新内容
    const codeElement = exportRef.value.querySelector('.export-content code');
    if (codeElement) {
      codeElement.innerHTML = highlightedCode;
    }

    // 等待代码渲染完成
    await nextTick();

    // 获取实际代码宽度
    const actualWidth = codeElement?.scrollWidth || 0;
    
    // 计算所需的容器总宽度（代码宽度 + padding）
    const containerWidth = actualWidth + 80; // 80px 为左右 padding 的总和

    // 设置容器宽度
    exportRef.value.style.width = `${containerWidth}px`;

    const canvas = await html2canvas(exportRef.value, {
      scale: 2,
      backgroundColor: '#ffffff',
      logging: false,
      useCORS: true,
      onclone: (clonedDoc: Document) => {
        const clonedElement = clonedDoc.getElementById('code-export');
        if (clonedElement) {
          clonedElement.style.transform = 'none';
          clonedElement.style.left = '0';
          clonedElement.style.top = '0';
          clonedElement.style.width = `${containerWidth}px`;
        }
      }
    });

    const image = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.download = `code-${new Date().getTime()}.png`;
    link.href = image;
    link.click();

    MessagePlugin.success('导出成功！');
  } catch (error) {
    console.error('导出失败:', error);
    MessagePlugin.error('导出失败，请重试');
  } finally {
    isExporting.value = false;
    if (exportRef.value) {
      exportRef.value.style.width = '';
    }
  }
};
</script>

<style scoped>
.code-export {
  position: fixed;
  left: -9999px;
  background: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  min-width: 300px;
  width: max-content;
}

.export-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e5e6eb;
}

.export-logo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.export-title {
  font-size: 18px;
  font-weight: 500;
  color: var(--td-text-color-primary);
}

.export-content {
  background-color: #f6f8fa;
  padding: 16px;
  border-radius: 6px;
  width: max-content;
  min-width: 100%;
}

.export-content pre {
  margin: 0;
  padding: 0;
  width: max-content;
  min-width: 100%;
}

.export-content code {
  font-family: 'Fira Code', monospace;
  font-size: 14px;
  line-height: 1.6;
  tab-size: 4;
  white-space: pre;
  display: inline-block;
  width: max-content;
}

.export-footer {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #e5e6eb;
  display: flex;
  justify-content: flex-end;
}

.website {
  font-size: 14px;
  color: var(--td-text-color-secondary);
  font-family: 'Fira Code', monospace;
}

/* 添加代码高亮的自定义样式 */
.export-content .hljs {
  background: #f6f8fa;
  padding: 0;
}

/* 添加水平滚动条样式（用于预览时） */
.export-content::-webkit-scrollbar {
  height: 8px;
}

.export-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.export-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.export-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style> 