import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "settings-container" }
const _hoisted_2 = { class: "setting-item" }
const _hoisted_3 = { class: "setting-item" }
const _hoisted_4 = { class: "setting-item" }
const _hoisted_5 = { class: "setting-item" }
const _hoisted_6 = { class: "setting-item" }
const _hoisted_7 = { class: "setting-item" }
const _hoisted_8 = { class: "drawer-footer" }


import { useEditorSettingsStore } from '@/store/editorSettings';


export default /*@__PURE__*/_defineComponent({
  __name: 'EditorSettings',
  props: {
  visible: Boolean,
  editor: Object,
},
  emits: ['update:visible'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
const settingsStore = useEditorSettingsStore();

const handleClose = () => {
  emit('update:visible', false);
};

const updateSettings = () => {
  if (props.editor) {
    const newSettings = {
      fontSize: settingsStore.settings.fontSize,
      tabSize: settingsStore.settings.tabSize,
      wordWrap: settingsStore.settings.wordWrap ? 'on' : 'off',
      lineNumbers: settingsStore.settings.lineNumbers ? 'on' : 'off',
      minimap: {
        enabled: settingsStore.settings.minimap
      },
      fontLigatures: settingsStore.settings.fontLigatures
    };
    
    settingsStore.updateSettings(settingsStore.settings);
    props.editor.updateOptions({
      ...newSettings
    });
  }
};

const resetSettings = () => {
  settingsStore.resetToDefault();
  updateSettings();
};

return (_ctx: any,_cache: any) => {
  const _component_t_input_number = _resolveComponent("t-input-number")!
  const _component_t_switch = _resolveComponent("t-switch")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_drawer = _resolveComponent("t-drawer")!

  return (_openBlock(), _createBlock(_component_t_drawer, {
    visible: __props.visible,
    size: '300px',
    header: "编辑器设置",
    onClose: handleClose
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_t_button, {
          theme: "default",
          onClick: handleClose
        }, {
          default: _withCtx(() => _cache[12] || (_cache[12] = [
            _createTextVNode("关闭")
          ])),
          _: 1
        }),
        _createVNode(_component_t_button, {
          theme: "danger",
          variant: "text",
          onClick: resetSettings
        }, {
          default: _withCtx(() => _cache[13] || (_cache[13] = [
            _createTextVNode("恢复默认设置")
          ])),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "setting-label" }, "字体大小", -1)),
          _createVNode(_component_t_input_number, {
            modelValue: _unref(settingsStore).settings.fontSize,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(settingsStore).settings.fontSize) = $event)),
            min: 12,
            max: 90,
            onChange: updateSettings
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[7] || (_cache[7] = _createElementVNode("span", { class: "setting-label" }, "缩进大小", -1)),
          _createVNode(_component_t_input_number, {
            modelValue: _unref(settingsStore).settings.tabSize,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(settingsStore).settings.tabSize) = $event)),
            min: 2,
            max: 8,
            onChange: updateSettings
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[8] || (_cache[8] = _createElementVNode("span", { class: "setting-label" }, "自动换行", -1)),
          _createVNode(_component_t_switch, {
            modelValue: _unref(settingsStore).settings.wordWrap,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(settingsStore).settings.wordWrap) = $event)),
            onChange: updateSettings
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[9] || (_cache[9] = _createElementVNode("span", { class: "setting-label" }, "显示行号", -1)),
          _createVNode(_component_t_switch, {
            modelValue: _unref(settingsStore).settings.lineNumbers,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(settingsStore).settings.lineNumbers) = $event)),
            onChange: updateSettings
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[10] || (_cache[10] = _createElementVNode("span", { class: "setting-label" }, "显示缩略图", -1)),
          _createVNode(_component_t_switch, {
            modelValue: _unref(settingsStore).settings.minimap,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(settingsStore).settings.minimap) = $event)),
            onChange: updateSettings
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[11] || (_cache[11] = _createElementVNode("span", { class: "setting-label" }, "字体连字", -1)),
          _createVNode(_component_t_switch, {
            modelValue: _unref(settingsStore).settings.fontLigatures,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(settingsStore).settings.fontLigatures) = $event)),
            onChange: updateSettings
          }, null, 8, ["modelValue"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})