import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import TDesign from 'tdesign-vue-next';
import { createPinia } from 'pinia';
import loader from '@monaco-editor/loader';

createApp(App).use(createPinia()).use(router).use(TDesign).mount('#app')

loader.config({
    paths: {
        vs: '/vs', // 对应静态资源目录的路径
    },
});
