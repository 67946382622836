import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","align-items":"center","gap":"16px"} }
const _hoisted_2 = {
  key: 0,
  class: "test-cases-details"
}
const _hoisted_3 = { class: "test-case-header" }
const _hoisted_4 = { class: "detail-section" }
const _hoisted_5 = { class: "detail-item" }
const _hoisted_6 = { class: "detail-value" }
const _hoisted_7 = { class: "detail-item" }
const _hoisted_8 = { class: "detail-value" }
const _hoisted_9 = { class: "io-section" }
const _hoisted_10 = { class: "io-item" }
const _hoisted_11 = {
  key: 0,
  class: "io-item"
}
const _hoisted_12 = { class: "io-item" }
const _hoisted_13 = {
  key: 1,
  class: "io-item error"
}
const _hoisted_14 = {
  key: 0,
  class: "result-section"
}
const _hoisted_15 = { class: "drawer-footer" }

import { computed, ref } from 'vue';

interface TestCase {
  input: string;
  expected?: string;
  output?: string;
  status?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'RunDetails',
  props: {
  visible: { type: Boolean, default: false },
  runInfo: { type: Array, default: () => [] },
  testCases: { type: Array, default: () => [] }
},
  emits: ['update:visible'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const handleClose = () => {
  emit('update:visible', false);
};

const getStatusText = (status: string) => {
  const statusMap: Record<string, string> = {
    'Accepted': '运行成功',
    'Memory Limit Exceeded': '内存超限',
    'Time Limit Exceeded': '时间超限',
    'Output Limit Exceeded': '输出超限',
    'File Error': '文件错误',
    'NonzeroExitStatus': '运行异常',
    'Signalled': '进程被终止',
    'Internal Error': '内部错误'
  };
  return statusMap[status] || status;
};

const getStatusTheme = (status: string) => {
  return status === 'Accepted' ? 'success' : 'danger';
};

const isOutputMatch = (result: any, index: number) => {
  const testCase = props.testCases[index];
  if (!testCase?.expected) return true;
  const expectedOutput = testCase.expected.trim();
  const actualOutput = result.files?.stdout?.trim() || '';
  return expectedOutput === actualOutput;
};

const showErrorOnly = ref(false);

const filteredResults = computed(() => {
  if (!showErrorOnly.value) {
    return props.runInfo;
  }
  
  return props.runInfo.map((result: any, index: number) => {
    if (result.status !== 'Accepted' || 
        (result.status === 'Accepted' && !isOutputMatch(result, index))) {
      return { ...result, originalIndex: index };
    }
    return null;
  }).filter(item => item !== null);
});

return (_ctx: any,_cache: any) => {
  const _component_t_switch = _resolveComponent("t-switch")!
  const _component_t_tag = _resolveComponent("t-tag")!
  const _component_t_textarea = _resolveComponent("t-textarea")!
  const _component_t_alert = _resolveComponent("t-alert")!
  const _component_t_empty = _resolveComponent("t-empty")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_icon = _resolveComponent("t-icon")!
  const _component_t_tooltip = _resolveComponent("t-tooltip")!
  const _component_t_drawer = _resolveComponent("t-drawer")!

  return (_openBlock(), _createBlock(_component_t_drawer, {
    visible: __props.visible,
    size: '50%',
    "size-draggable": true,
    onClose: handleClose,
    header: "运行详情",
    closeOnOverlayClick: true,
    showOverlay: true
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("span", null, "运行详情", -1)),
        _createVNode(_component_t_switch, {
          modelValue: showErrorOnly.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((showErrorOnly).value = $event)),
          label: ['仅错误', '全部']
        }, null, 8, ["modelValue"])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_t_button, {
          theme: "default",
          onClick: handleClose
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode("关闭")
          ])),
          _: 1
        }),
        _createVNode(_component_t_tooltip, { content: "拖动中间分界线可调整窗口大小" }, {
          default: _withCtx(() => [
            _createVNode(_component_t_icon, {
              name: "help-circle",
              style: {"margin-left":"8px","color":"var(--td-text-color-secondary)"}
            })
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      (__props.runInfo && __props.runInfo.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredResults.value, (result, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: result.originalIndex || index,
                class: "test-case-detail"
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_t_tag, {
                    theme: "default",
                    variant: "light",
                    class: "case-number"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" 测试用例 #" + _toDisplayString((result.originalIndex || index) + 1), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_t_tag, {
                    theme: getStatusTheme(result.status),
                    variant: "light"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(getStatusText(result.status)), 1)
                    ]),
                    _: 2
                  }, 1032, ["theme"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "detail-label" }, "运行时间：", -1)),
                    _createElementVNode("div", _hoisted_6, _toDisplayString((result.time / 1_000_000).toFixed(2)) + "ms", 1)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "detail-label" }, "内存占用：", -1)),
                    _createElementVNode("div", _hoisted_8, _toDisplayString((result.memory / 1024).toFixed(2)) + "KB", 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "io-label" }, "输入：", -1)),
                    _createVNode(_component_t_textarea, {
                      readonly: "",
                      value: __props.testCases[result.originalIndex || index]?.input ?? '',
                      autosize: { minRows: 2, maxRows: 4 }
                    }, null, 8, ["value"])
                  ]),
                  (__props.testCases[result.originalIndex || index]?.expected)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "io-label" }, "预期输出：", -1)),
                        _createVNode(_component_t_textarea, {
                          readonly: "",
                          value: __props.testCases[result.originalIndex || index]?.expected ?? '',
                          autosize: { minRows: 2, maxRows: 9 }
                        }, null, 8, ["value"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_12, [
                    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "io-label" }, "实际输出：", -1)),
                    _createVNode(_component_t_textarea, {
                      readonly: "",
                      value: result.files?.stdout || '',
                      autosize: { minRows: 2, maxRows: 9 }
                    }, null, 8, ["value"])
                  ]),
                  (result.files?.stderr)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "io-label" }, "错误信息：", -1)),
                        _createVNode(_component_t_textarea, {
                          readonly: "",
                          value: result.files.stderr,
                          autosize: { minRows: 2, maxRows: 9 }
                        }, null, 8, ["value"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                (__props.testCases[result.originalIndex || index]?.expected && result.status === 'Accepted')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createVNode(_component_t_alert, {
                        theme: isOutputMatch(result, result.originalIndex || index) ? 'success' : 'error',
                        message: isOutputMatch(result, result.originalIndex || index) ? 'Accepted 输出结果正确' : 'Wrong Answer 输出结果错误'
                      }, null, 8, ["theme", "message"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createBlock(_component_t_empty, {
            key: 1,
            description: "请先运行代码"
          }))
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})