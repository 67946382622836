import { defineStore } from 'pinia';

interface EditorSettings {
  fontSize: number;
  tabSize: number;
  wordWrap: boolean | 'off';
  lineNumbers: boolean;
  minimap: boolean;
  fontLigatures: boolean;
}

const defaultSettings: EditorSettings = {
  fontSize: 14,
  tabSize: 4,
  wordWrap: false,
  lineNumbers: true,
  minimap: true,
  fontLigatures: true,
};

export const useEditorSettingsStore = defineStore('editorSettings', {
  state: () => ({
    settings: JSON.parse(localStorage.getItem('editorSettings') || JSON.stringify(defaultSettings))
  }),
  
  actions: {
    updateSettings(newSettings: Partial<EditorSettings>) {
      this.settings = { ...this.settings, ...newSettings };
      localStorage.setItem('editorSettings', JSON.stringify(this.settings));
    },
    
    resetToDefault() {
      this.settings = { ...defaultSettings };
      localStorage.setItem('editorSettings', JSON.stringify(defaultSettings));
    }
  }
}); 