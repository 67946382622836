<template>
  <t-drawer
    :visible="visible"
    :size="'300px'"
    header="编辑器设置"
    @close="handleClose"
  >
    <div class="settings-container">
      <div class="setting-item">
        <span class="setting-label">字体大小</span>
        <t-input-number
          v-model="settingsStore.settings.fontSize"
          :min="12"
          :max="90"
          @change="updateSettings"
        />
      </div>

      <div class="setting-item">
        <span class="setting-label">缩进大小</span>
        <t-input-number
          v-model="settingsStore.settings.tabSize"
          :min="2"
          :max="8"
          @change="updateSettings"
        />
      </div>

      <div class="setting-item">
        <span class="setting-label">自动换行</span>
        <t-switch
          v-model="settingsStore.settings.wordWrap"
          @change="updateSettings"
        />
      </div>

      <div class="setting-item">
        <span class="setting-label">显示行号</span>
        <t-switch
          v-model="settingsStore.settings.lineNumbers"
          @change="updateSettings"
        />
      </div>

      <div class="setting-item">
        <span class="setting-label">显示缩略图</span>
        <t-switch
          v-model="settingsStore.settings.minimap"
          @change="updateSettings"
        />
      </div>

      <div class="setting-item">
        <span class="setting-label">字体连字</span>
        <t-switch
          v-model="settingsStore.settings.fontLigatures"
          @change="updateSettings"
        />
      </div>
    </div>
    <template #footer>
      <div class="drawer-footer">
        <t-button theme="default" @click="handleClose">关闭</t-button>
        <t-button theme="danger" variant="text" @click="resetSettings">恢复默认设置</t-button>
      </div>
    </template>
  </t-drawer>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue';
import { useEditorSettingsStore } from '@/store/editorSettings';

const props = defineProps({
  visible: Boolean,
  editor: Object,
});

const emit = defineEmits(['update:visible']);
const settingsStore = useEditorSettingsStore();

const handleClose = () => {
  emit('update:visible', false);
};

const updateSettings = () => {
  if (props.editor) {
    const newSettings = {
      fontSize: settingsStore.settings.fontSize,
      tabSize: settingsStore.settings.tabSize,
      wordWrap: settingsStore.settings.wordWrap ? 'on' : 'off',
      lineNumbers: settingsStore.settings.lineNumbers ? 'on' : 'off',
      minimap: {
        enabled: settingsStore.settings.minimap
      },
      fontLigatures: settingsStore.settings.fontLigatures
    };
    
    settingsStore.updateSettings(settingsStore.settings);
    props.editor.updateOptions({
      ...newSettings
    });
  }
};

const resetSettings = () => {
  settingsStore.resetToDefault();
  updateSettings();
};
</script>

<style scoped>
.settings-container {
  padding: 16px;
}

.setting-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.setting-label {
  font-size: 14px;
  color: var(--td-text-color-primary);
}

.drawer-footer {
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
}
</style> 