<template>
  <div style="display: flex; align-items: center; gap: 10px;">
    <t-button
        :loading="isLoading"
        variant="outline"
        theme="success"
        class="custom-button"
        @click="runCode"
    >
      <template v-if="!isLoading" #icon>
        <PlayCircleStrokeIcon/>
      </template>
      <span v-if="!isLoading">运行</span>
      <span v-else>运行中</span>
    </t-button>
    
    <t-select
        v-model="currentTheme"
        class="theme-select"
        placeholder="选择主题"
        @change="changeTheme"
        style="width: 120px;"
    >
      <t-option v-for="theme in themes" :key="theme.value" :value="theme.value" :label="theme.label"/>
    </t-select>

    <t-select
        v-model="currentLanguage"
        class="language-select"
        placeholder="选择语言"
        @change="changeLanguage"
        style="width: 120px; margin-left: 8px;"
    >
      <t-option v-for="lang in languages" :key="lang.value" :value="lang.value" :label="lang.label"/>
    </t-select>

    <t-button
      variant="outline"
      theme="default"
      @click="openSettings"
    >
      <template #icon>
        <SettingIcon />
      </template>
    </t-button>

    <t-button
      variant="outline"
      theme="primary"
      @click="openAIExplainer"
    >
      <template #icon>
        <AppIcon />
      </template>
      <span>AI分析</span>
    </t-button>

    <ShareCode 
      :code="getCurrentCode()"
      :language="currentLanguage"
    />
  </div>
  
  <div class="editor-container">
    <div class="editor-section" :style="{ flex: leftFlex }">
      <div class="bordered-div">
        <div id="code-editor" ref="codeEditorRef" style="height: 80vh"/>
      </div>
    </div>
    
    <div 
      class="resizer" 
      @mousedown="startResize"
      @touchstart="startResize"
    >
      <div class="resizer-line"></div>
    </div>
    
    <div class="right-section" :style="{ flex: rightFlex }">
      <div class="container">
        <div class="action-buttons">
          <t-button 
            variant="outline" 
            theme="success" 
            :loading="isLoading"
            @click="runCode"
          >
            <template v-if="!isLoading" #icon>
              <PlayCircleStrokeIcon/>
            </template>
            <span v-if="!isLoading">运行</span>
            <span v-else>运行中</span>
          </t-button>

          <t-button 
            variant="outline" 
            theme="primary" 
            @click="openRunDetails"
            style="flex: 1;"
          >
            运行详情
          </t-button>
        </div>
        <div class="scrollable-container">
          <div class="test-cases-container">
            <div v-for="(testCase, index) in testCases" :key="index" class="test-case-item">
              <div class="test-case-header">
                <span>测试用例 #{{ index + 1 }}</span>
                <t-button 
                  theme="danger" 
                  variant="text" 
                  size="small" 
                  @click="removeTestCase(index)"
                  v-if="testCases.length > 1"
                >
                  删除
                </t-button>
              </div>
              <div 
                class="test-case-content"
                :class="{
                  'success-case': runInfo?.[index]?.status === 'Accepted' && isOutputMatch(runInfo[index], index),
                  'error-case': runInfo?.[index]?.status === 'Accepted' && !isOutputMatch(runInfo[index], index)
                }"
              >
                <div class="input-section">
                  <div class="input-label">输入：</div>
                  <t-textarea
                    v-model="testCase.input"
                    :placeholder="`输入第 ${index + 1} 组测试用例`"
                    :autosize="{ minRows: 2, maxRows: 3 }"
                  />
                </div>
                <div class="expected-section">
                  <div class="input-label">预期输出：</div>
                  <t-textarea
                    v-model="testCase.expected"
                    :placeholder="'输入预期结果（可选）'"
                    :autosize="{ minRows: 2, maxRows: 3 }"
                  />
                </div>
                <div class="actual-section" 
                     v-if="runInfo?.[index]?.status === 'Accepted' && 
                          testCase.expected && 
                          !isOutputMatch(runInfo[index], index)"
                >
                  <div class="input-label error-text">实际输出：</div>
                  <t-textarea
                    readonly
                    :value="runInfo[index].files?.stdout || ''"
                    :autosize="{ minRows: 2, maxRows: 3 }"
                  />
                </div>
              </div>
            </div>
            <t-button 
              theme="primary" 
              variant="text" 
              size="small" 
              @click="addTestCase"
              style="margin-top: 8px;"
            >
              + 添加测试用例
            </t-button>
          </div>

          <div class="output-section">
            <t-alert 
              v-if="runInfo.length > 0"
              :theme="getAlertTheme()"
              :message="getAlertMessage()"
            />
            <div v-if="output" class="output-container">
              <h4>输出：</h4>
              <pre class="output-content">{{ output }}</pre>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- 添加 RunDetails 组件 -->
  <RunDetails 
    :runInfo="runInfo" 
    :visible="drawerVisible" 
    :testCases="testCases"
    @update:visible="updateDrawerVisible"
  />

  <EditorSettings
    :visible="settingsVisible"
    :editor="codeEditor"
    @update:visible="updateSettingsVisible"
  />

  <!-- 添加 AIExplainer 组件 -->
  <AIExplainer
    :visible="aiExplainerVisible"
    :code="getCurrentCode()"
    @update:visible="updateAIExplainerVisible"
  />

  <div class="save-status" v-if="showSaveStatus">
    <t-tag theme="success" variant="light">已自动保存</t-tag>
  </div>

</template>

<script lang="ts" setup>
import {nextTick, onMounted, ref, toRaw, watch, onUnmounted, onBeforeUnmount} from "vue";
import {PlayCircleStrokeIcon, SettingIcon, AppIcon, ShareIcon} from 'tdesign-icons-vue-next';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import loader from '@monaco-editor/loader';
// import 'monaco-editor/esm/vs/basic-languages/javascript/javascript.contribution'
import * as monaco from 'monaco-editor';
import editorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';
import jsonWorker from 'monaco-editor/esm/vs/language/json/json.worker?worker';
import cssWorker from 'monaco-editor/esm/vs/language/css/css.worker?worker';
import htmlWorker from 'monaco-editor/esm/vs/language/html/html.worker?worker';
import tsWorker from 'monaco-editor/esm/vs/language/typescript/ts.worker?worker';
import {useEditorStore} from '@/store/editor';
import RunDetails from "@/components/RunDetails.vue";
import EditorSettings from '@/components/EditorSettings.vue';
import {useEditorSettingsStore} from '@/store/editorSettings';
import { MessagePlugin } from 'tdesign-vue-next';
import AIExplainer from '@/components/AIExplainer.vue';
import ShareCode from '@/components/ShareCode.vue';

// 配置 Monaco 编辑器的本地化
loader.config({
  'vs/nls': {
    availableLanguages: {
      '*': 'zh-cn'
    }
  }
});

const editorStore = useEditorStore();
const settingsStore = useEditorSettingsStore();

// 判题结果相关状态
const isSuccessful = ref(false);
const isError = ref(false);
const output = ref('');
const isLoading = ref(false);
const runInfo = ref<any[]>([]);
const drawerVisible = ref(false);

// Monaco 编辑器和其他必要的变量
const codeEditorRef = ref();
const codeEditor = ref();
const value = ref(editorStore.code || ''); // 初始化为 store 值
const value3 = ref(''); // 用于输入测试用例的变量

// 填充示例代码
const fillValue = () => {
  if (!codeEditor.value) {
    return;
  }
  // 改变编辑器中的内容
  toRaw(codeEditor.value).setValue(`#include<iostream>
using namespace std;
int main() {
    cout<<"Hello world!"<<endl;
    return 0;
}`);
};

const updateDrawerVisible = (newVisible: boolean) => {
  drawerVisible.value = newVisible;
};

const openRunDetails = () => {
  drawerVisible.value = true;
}

// 主题相关的状态
const currentTheme = ref('vs');
const themes = [
  { value: 'vs', label: '浅色主题' },
  { value: 'vs-dark', label: '深色主题' },
  { value: 'hc-black', label: '高对比度' }
];

// 语言相关的状态
const languages = [
  { value: 'cpp', label: 'C++' },
  { value: 'c', label: 'C' },
  { value: 'java', label: 'Java' },
  { value: 'python', label: 'Python' },
  { value: 'javascript', label: 'JavaScript' },
  { value: 'typescript', label: 'TypeScript' },
  { value: 'go', label: 'Go' },
  { value: 'rust', label: 'Rust' },
  { value: 'csharp', label: 'C#' },
  { value: 'php', label: 'PHP' },
  { value: 'ruby', label: 'Ruby' },
  { value: 'swift', label: 'Swift' },
  { value: 'kotlin', label: 'Kotlin' },
  { value: 'scala', label: 'Scala' },
  { value: 'sql', label: 'SQL' },
];

const currentLanguage = ref('cpp');

// 监听主题变化
watch(currentTheme, async (newTheme) => {
  if (codeEditor.value) {
    const monacoInstance = await loader.init();
    monacoInstance.editor.setTheme(newTheme);
  }
});

// 配置 Monaco Editor 的 workers
window.MonacoEnvironment = {
  getWorker(_, label) {
    if (label === 'json') {
      return new jsonWorker();
    }
    if (label === 'css' || label === 'scss' || label === 'less') {
      return new cssWorker();
    }
    if (label === 'html' || label === 'handlebars' || label === 'razor') {
      return new htmlWorker();
    }
    if (label === 'typescript' || label === 'javascript') {
      return new tsWorker();
    }
    return new editorWorker();
  }
};

// 初始化编辑器
onMounted(async () => {
  if (!codeEditorRef.value) return;

  try {
    const monacoInstance = await loader.init();
    
    // 设置初始主题
    monacoInstance.editor.setTheme(currentTheme.value);
    
    // 创建编辑器实例
    codeEditor.value = monacoInstance.editor.create(codeEditorRef.value, {
      value: value.value,
      language: currentLanguage.value,
      automaticLayout: true,
      fontFamily: "Fira Code",
      fontSize: settingsStore.settings.fontSize,
      tabSize: settingsStore.settings.tabSize,
      wordWrap: settingsStore.settings.wordWrap ? 'on' : 'off',
      lineNumbers: settingsStore.settings.lineNumbers ? 'on' : 'off',
      minimap: {
        enabled: settingsStore.settings.minimap
      },
      fontLigatures: settingsStore.settings.fontLigatures,
      theme: currentTheme.value,
      suggestSelection: 'first',
      wordBasedSuggestions: 'matchingDocuments',
      quickSuggestions: true,
    });

    // 添加窗口大小变化的监听
    window.addEventListener('resize', () => {
      if (codeEditor.value) {
        const editor = toRaw(codeEditor.value);
        editor.layout();
      }
    });
    
  } catch (error) {
    console.error('Monaco editor initialization failed:', error);
  }

  // 初始化代码高亮
  hljs.highlightAll();

  // 设置自动保存
  setupAutoSave();
});

// 主题切换方法
const changeTheme = (newTheme: string) => {
  currentTheme.value = newTheme;
};

// 语言切换方法
const changeLanguage = async (newLang: string) => {
  if (!codeEditor.value) return;
  
  // 更新编辑器语言
  const editor = toRaw(codeEditor.value);
  const model = editor.getModel();
  
  if (model) {
    monaco.editor.setModelLanguage(model, newLang);
  }

  if (newLang !== 'cpp') {
    output.value = '非C++语言目前仅支持代码高亮，暂不支持运行，持续开发中...';
    isSuccessful.value = false;
    isError.value = true;
  }
};

// 题逻
interface TestCase {
  input: string;
  expected?: string;
  output?: string;
  status?: string;
}

const testCases = ref<TestCase[]>([{ input: '', expected: '' }]);

const addTestCase = () => {
  testCases.value.push({ input: '', expected: '' });
};

const removeTestCase = (index: number) => {
  testCases.value.splice(index, 1);
};

const runCode = async () => {
  if (!codeEditor.value) {
    console.error("Code editor not initialized.");
    return;
  }

  // 检查当前语言
  if (currentLanguage.value !== 'cpp') {
    MessagePlugin.warning({
      content: '非C++语言目前仅支持码高亮，暂不支持运行，持续开发中...',
      duration: 3000,
      closeBtn: true,
    });
    return;
  }

  isLoading.value = true;
  const code = toRaw(codeEditor.value).getValue();
  
  try {
    const results = await Promise.all(
      testCases.value.map(testCase => 
        submitToJudgeSystem(code, testCase.input)
      )
    );
    
    runInfo.value = results;
    displayResults(results);
  } catch (error) {
    console.error("提交失败:", error);
    displayError(error);
  } finally {
    isLoading.value = false;
  }
};

// 向判题系统提交请求
const submitToJudgeSystem = async (code: string, input: string) => {
  const response = await fetch('https://panti.emoera.top/run.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      code, // Monaco 编辑器中的代码
      input, // 用户输入的测试用例
    }),
  });

  if (!response.ok) {
    throw new Error('提交失败');
  }

  const result = await response.json();
  return result;
};

// 根据判题结果更新 UI 状态
enum Status {
  Accepted = 'Accepted', // 正常情况
  MemoryLimitExceeded = 'Memory Limit Exceeded', // 内存超限
  TimeLimitExceeded = 'Time Limit Exceeded', // 时间超限
  OutputLimitExceeded = 'Output Limit Exceeded', // 输出超限
  FileError = 'File Error', // 文件错误
  NonzeroExitStatus = 'Nonzero Exit Status', // 非 0 退出值
  Signalled = 'Signalled', // 进程被信号终止
  InternalError = 'Internal Error', // 内部错误
}

const getStatusText = (status: string) => {
  const statusMap: Record<string, string> = {
    'Accepted': '运行成功',
    'Memory Limit Exceeded': '内存超限',
    'Time Limit Exceeded': '时间限',
    'Output Limit Exceeded': '输出超限',
    'File Error': '文件错误',
    'NonzeroExitStatus': '运行异常',
    'Signalled': '进程被终止',
    'Internal Error': '内部错误'
  };
  return statusMap[status] || status;
};

const displayResults = (results: any[]) => {
  // 只要收到服务器返回的结果就显示运行成功
  isSuccessful.value = true;
  isError.value = false;
  
  output.value = results.map((result, index) => {
    const testCase = testCases.value[index];
    let outputText = `测试用例 #${index + 1}:\n`;
    
    if (result.status === Status.Accepted) {
      const actualOutput = (result.files?.stdout || '').trim();
      outputText += `状态: 通过\n`;
      
      // 如果有预期输出，进行对比
      if (testCase.expected && testCase.expected.trim()) {
        const expectedOutput = testCase.expected.trim();
        const isMatch = actualOutput === expectedOutput;
        
        outputText += isMatch 
          ? `结果: 答案正确 ✓\n` 
          : `结果: 答案错误 ✗\n`;
        
        if (!isMatch) {
          outputText += `预期输出:\n${expectedOutput}\n`;
          outputText += `实际输出:\n${actualOutput}\n`;
          outputText += `差异说明:\n`;
          
          if (actualOutput.length !== expectedOutput.length) {
            outputText += `- 输出长度不一致（预期: ${expectedOutput.length}, 实际: ${actualOutput.length}）\n`;
          }
          
          // 检查是否只是空白字符的差异
          const normalizedExpected = expectedOutput.replace(/\s+/g, ' ');
          const normalizedActual = actualOutput.replace(/\s+/g, ' ');
          if (normalizedExpected === normalizedActual) {
            outputText += `- 输出内容相同，但空白字符不一致\n`;
          }
          
          // 检查是否有多余或缺少的换行
          const expectedLines = expectedOutput.split('\n');
          const actualLines = actualOutput.split('\n');
          if (expectedLines.length !== actualLines.length) {
            outputText += `- 换行数量不一致（预期: ${expectedLines.length}, 实际: ${actualLines.length}）\n`;
          }
        }
      } else {
        outputText += `输出:\n${actualOutput}\n`;
      }
    } else {
      outputText += `状态: ${getStatusText(result.status)}\n`;
      if (result.files?.stderr) {
        outputText += `错误信息:\n${result.files.stderr}\n`;
      }
    }
    return outputText;
  }).join('\n---\n');
};

const displayError = (error: any) => {
  isError.value = true;
  isSuccessful.value = false;
  output.value = `请求失败: ${error.message || '未知错误'}`;
};

// 添加 isOutputMatch 函数
const isOutputMatch = (result: any, index: number) => {
  const testCase = testCases.value[index];
  if (!testCase?.expected) return true;
  const expectedOutput = testCase.expected.trim();
  const actualOutput = result.files?.stdout?.trim() || '';
  return expectedOutput === actualOutput;
};

const getAlertTheme = () => {
  if (!runInfo.value.length) return 'success';
  
  const totalCases = runInfo.value.length;
  const passedCases = runInfo.value.filter((result, index) => 
    result.status === 'Accepted' && isOutputMatch(result, index)
  ).length;
  
  if (passedCases === totalCases) return 'success';
  if (passedCases > 0) return 'warning';
  return 'error';
};

const getAlertMessage = () => {
  if (!runInfo.value.length) return '';
  
  const totalCases = runInfo.value.length;
  const passedCases = runInfo.value.filter((result, index) => 
    result.status === 'Accepted' && isOutputMatch(result, index)
  ).length;
  
  if (passedCases === totalCases) return '全部通过';
  if (passedCases > 0) return `部分通过（${passedCases}/${totalCases}）`;
  return '样例未通过';
};

// 添加设置相关的状态
const settingsVisible = ref(false);

// 添加以下方法
const openSettings = () => {
  settingsVisible.value = true;
};

const updateSettingsVisible = (newVisible: boolean) => {
  settingsVisible.value = newVisible;
};

// 添加 AI 解释相关的状态
const aiExplainerVisible = ref(false);

// 添加获取当前代码的方法
const getCurrentCode = () => {
  if (!codeEditor.value) return '';
  return toRaw(codeEditor.value).getValue();
};

// 添加打开 AI 解释器的方法
const openAIExplainer = () => {
  aiExplainerVisible.value = true;
};

// 添加更新 AI 解释器可见性的方法
const updateAIExplainerVisible = (newVisible: boolean) => {
  aiExplainerVisible.value = newVisible;
};

// 添加新的状态
const leftFlex = ref(7);
const rightFlex = ref(3);
const isResizing = ref(false);
const startX = ref(0);
const startLeftFlex = ref(0);

// 添加拖动相关方法
const startResize = (e: MouseEvent | TouchEvent) => {
  isResizing.value = true;
  startX.value = 'touches' in e ? e.touches[0].clientX : e.clientX;
  startLeftFlex.value = leftFlex.value;
  
  document.addEventListener('mousemove', handleResize);
  document.addEventListener('touchmove', handleResize);
  document.addEventListener('mouseup', stopResize);
  document.addEventListener('touchend', stopResize);
  
  // 添加禁止选择类
  document.body.classList.add('no-select');
};

const handleResize = (e: MouseEvent | TouchEvent) => {
  if (!isResizing.value) return;
  
  requestAnimationFrame(() => {
    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const containerWidth = document.querySelector('.editor-container')?.clientWidth || 1000;
    
    const movementRatio = (clientX - startX.value) / containerWidth;
    const newLeftFlex = Math.max(2, Math.min(8, startLeftFlex.value + movementRatio * 10));
    
    leftFlex.value = newLeftFlex;
    rightFlex.value = 10 - newLeftFlex;
    
    // 强制触发 Monaco Editor 重新布局
    if (codeEditor.value) {
      const editor = toRaw(codeEditor.value);
      editor.layout();
      
      // 添加一个小延时来确保布局更新
      setTimeout(() => {
        editor.layout();
      }, 10);
    }
  });
};

const stopResize = () => {
  isResizing.value = false;
  document.removeEventListener('mousemove', handleResize);
  document.removeEventListener('touchmove', handleResize);
  document.removeEventListener('mouseup', stopResize);
  document.removeEventListener('touchend', stopResize);
  
  document.body.classList.remove('no-select');
  
  // 最后一次布局更新
  if (codeEditor.value) {
    toRaw(codeEditor.value).layout();
  }
};

// 组件卸载时清理事件监听
onUnmounted(() => {
  document.removeEventListener('mousemove', handleResize);
  document.removeEventListener('touchmove', handleResize);
  document.removeEventListener('mouseup', stopResize);
  document.removeEventListener('touchend', stopResize);
  window.removeEventListener('resize', () => {
    if (codeEditor.value) {
      const editor = toRaw(codeEditor.value);
      editor.layout();
    }
  });
});

// 添加自动保存功能
const autoSaveInterval = ref<number | null>(null);

// 在组件卸载前清除定时器
onBeforeUnmount(() => {
  if (autoSaveInterval.value) {
    clearInterval(autoSaveInterval.value);
  }
});

// 在编辑器初始化后设置自动保存
const handleEditorMounted = (editor: any) => {
  // ... 现有的编辑器初始化代码 ...
  
  // 设置自动保存
  setupAutoSave();
};

const showSaveStatus = ref(false);

const showSavedStatus = () => {
  showSaveStatus.value = true;
  setTimeout(() => {
    showSaveStatus.value = false;
  }, 2000);
};

// 修改自动保存函数
const setupAutoSave = () => {
  autoSaveInterval.value = window.setInterval(() => {
    if (codeEditor.value) {
      const currentCode = toRaw(codeEditor.value).getValue();
      const previousCode = editorStore.code;
      if (currentCode !== previousCode) {
        editorStore.setCode(currentCode);
        showSavedStatus();
      }
    }
  }, 1000);
};

</script>

<style scoped>
#code-editor {
  text-align: left; /* 确保文本左对齐 */
  margin: 0;
  padding: 0;
}

.bordered-div {
  border: 1px solid lightgray; /* 设置边框颜色和宽度 */
}

.custom-button {
  margin: 10px 0; /* 上下边距 */
}

/*右侧*/
.container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  padding: 0 10px;
  position: relative;
}

.scrollable-container {
  flex: 1;
  overflow-y: auto;
  padding-right: 8px;
  margin-bottom: 10px;
}

.test-cases-container {
  margin-bottom: 16px;
}

.test-case-item {
  margin-bottom: 12px;
  border: 1px solid #e5e6eb;
  border-radius: 6px;
  padding: 12px;
  background-color: #fff;
}

.output-section {
  margin-top: 16px;
}

.output-container {
  margin-top: 12px;
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 12px;
}

.output-content {
  margin: 8px 0;
  padding: 12px;
  background-color: white;
  border-radius: 4px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: 'Fira Code', monospace;
  font-size: 14px;
  line-height: 1.5;
  max-height: 200px;
  overflow-y: auto;
}

.action-buttons {
  display: flex;
  gap: 10px;
  padding: 10px 0;
  background-color: #fff;
  border-top: 1px solid #e5e6eb;
}

/* 自定义滚动条样式 */
.scrollable-container::-webkit-scrollbar {
  width: 6px;
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #999;
}

@font-face {
  font-family: 'Fira Code';
  src: url('@/assets/fonts/FiraCode/FiraCode-Regular.woff2') format('woff2'),
  url('@/assets/fonts/FiraCode/FiraCode-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* 设置 Monaco Editor 使用 Fira Code 字体 */
.monaco-editor {
  font-family: 'Fira Code', monospace;
}

h4 {
  margin: 0;
  color: #666;
  font-size: 14px;
}

.test-case-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.test-case-header span {
  font-weight: 500;
  color: #666;
}

.test-case-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.success-case {
  background-color: rgba(82, 196, 26, 0.1);
}

.error-case {
  background-color: rgba(227, 77, 89, 0.1);
}

.input-section,
.expected-section {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.input-label {
  font-size: 14px;
  color: #666;
  font-weight: 500;
}

.error-text {
  color: var(--td-error-color);
}

.actual-section {
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-top: 1px dashed var(--td-error-color-1);
}

.editor-container {
  position: relative;
  display: flex;
  width: 100%;
  gap: 0;
  height: 80vh;
}

.editor-section {
  position: relative;
  min-width: 0; /* 防止内容溢出 */
  transition: flex 0s;
}

.right-section {
  position: relative;
  min-width: 0;
  transition: flex 0s;
}

.resizer {
  width: 8px;
  cursor: col-resize;
  background: transparent;
  position: relative;
  z-index: 1;
  transition: background-color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: none;
  will-change: transform;
  flex: none;
}

.resizer-line {
  width: 2px;
  height: 100%;
  background-color: var(--td-component-border);
  transition: background-color 0.2s;
}

.resizer:hover .resizer-line,
.resizer:active .resizer-line {
  background-color: var(--td-brand-color);
}

/* 添加全局样式 */
:global(.no-select) {
  user-select: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
}

.t-col {
  transition: none; /* 移除可能的过渡效果 */
  will-change: flex; /* 提示浏览器 flex 值将会改变 */
}

.save-status {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

</style>
