<template>
  <!-- 添加加载遮罩 -->
<!--  <div class="loading-overlay" v-if="isLoading">-->
<!--    <div class="loading-content">-->
<!--      <img src="@/assets/elogo.jpg" alt="logo" class="loading-logo" />-->
<!--      <t-loading theme="dots" size="large" />-->
<!--    </div>-->
<!--  </div>-->

  <t-head-menu v-model="menu1Value" theme="light" @change="changeHandler">
    <template #logo>
      <img height="49" src="https://we.emoera.com/img/elogo.jpg" alt="logo" style="border-radius: 30%"/>
    </template>
    <t-menu-item value="item1" @click="goToHome"> E时代IDE </t-menu-item>
    <t-menu-item value="item2" @click="openExternalLink"> 更新日志 </t-menu-item>
    <t-menu-item value="item4" :disabled="true"> v0.3.4 </t-menu-item>
    <template #operations>
      <t-button variant="text" shape="square">
        <template #icon><t-icon name="mail" /></template>
      </t-button>
      <t-button variant="text" shape="square" @click="goToLogin">
        <template #icon><t-icon name="user" /></template>
      </t-button>
      <t-button variant="text" shape="square">
        <template #icon><t-icon name="ellipsis" /></template>
      </t-button>
    </template>
  </t-head-menu>

  <router-view v-slot="{ Component }">
    <!-- <transition name="fade" mode="out-in"> -->
      <component :is="Component" />
    <!-- </transition> -->
  </router-view>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const menu1Value = ref('item1');
const menu2Value = ref('item1');
const isLoading = ref(true);

onMounted(() => {
  // 模拟加载过程
  setTimeout(() => {
    isLoading.value = false;
  }, 1500);
});

function openExternalLink() {
  window.open('https://docs.qq.com/aio/DVHZpRFFTdUVIYlV2?p=BvAba1pUjsuoDHKNY65azz', '_blank'); // 在新标签页中打开
}

function goToLogin() {
  router.push('/login');
}

function goToHome() {
  router.push('/');
}

const changeHandler = (active) => {
  console.log('change', active);
};
</script>

<style lang="less">
// 添加加载遮罩样式
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--td-bg-color-container);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.loading-logo {
  width: 120px;
  height: 120px;
  object-fit: contain;
  border-radius: 24px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
}

// 添加页面过渡动画
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

// 保留现有样式...
.t-menu__operations {
  .t-button {
    margin-left: 8px;
  }
}

.t-demo-menu--dark {
  .t-button {
    color: #fff;
    &:hover {
      background-color: #4b4b4b;
      border-color: transparent;
      --ripple-color: #383838;
    }
  }
}
</style>
