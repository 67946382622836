<template>
  <t-drawer
    :visible="visible"
    :size="'50%'"
    header="AI代码分析"
    @close="handleClose"
    :closeOnOverlayClick="true"
    :showOverlay="true"
    :size-draggable="true"
  >
    <div class="ai-content">
      <template v-if="isLoading">
        <div class="loading-container">
          <t-loading theme="dots" size="medium" />
          <p>AI正在分析代码...</p>
        </div>
      </template>
      
      <template v-else-if="error">
        <t-alert
          theme="error"
          :message="error"
          class="error-message"
        />
      </template>
      
      <template v-else-if="!explanation">
        <div class="initial-state">
          <t-empty
            description='点击"分析代码"开始AI分析'
            icon="code-1"
          />
        </div>
      </template>
      
      <template v-else>
        <div class="explanation markdown-body" v-html="renderedExplanation"></div>
      </template>
    </div>

    <template #footer>
      <div class="drawer-footer">
        <t-button theme="default" @click="handleClose">关闭</t-button>
        <t-button 
          theme="primary" 
          @click="explainCode" 
          :loading="isLoading"
          :disabled="isLoading"
        >
          分析代码
        </t-button>
      </div>
    </template>
  </t-drawer>
</template>

<script lang="ts" setup>
import { ref, defineProps, defineEmits, onBeforeUnmount } from 'vue';
import { MessagePlugin } from 'tdesign-vue-next';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

// 配置 marked
marked.setOptions({
  gfm: true,
  breaks: true,
  pedantic: false
});

// 自定义处理空行
const renderer = {
  paragraph(text: any) {
    // 检查是否为空或只包含空白字符
    const content = text.text || text;
    if (typeof content === 'string' && /^\s*$/.test(content)) {
      return ''; // 直接返回空字符串，不生成空段落
    }
    return '<p>' + content + '</p>';
  }
};

marked.use({ renderer });

const props = defineProps({
  visible: Boolean,
  code: String,
});

const emit = defineEmits(['update:visible']);
const isLoading = ref(false);
const error = ref('');
const explanation = ref('');
const renderedExplanation = ref('');
const streamController = ref<AbortController | null>(null);

const handleClose = () => {
  emit('update:visible', false);
};

const explainCode = async () => {
  if (!props.code?.trim()) {
    error.value = '没有可分析的代码';
    return;
  }

  isLoading.value = true;
  error.value = '';
  explanation.value = '';
  renderedExplanation.value = '';
  
  if (streamController.value) {
    streamController.value.abort();
  }
  
  streamController.value = new AbortController();
  
  try {
    const response = await fetch('https://aiapi.emoera.com/api.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code: props.code,
        action: 'explain',
        stream: true
      }),
      signal: streamController.value.signal
    });

    if (!response.ok) {
      throw new Error('网络请求失败');
    }

    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error('无法读取响应流');
    }

    const decoder = new TextDecoder();
    
    for (;;) {
      const { done, value } = await reader.read();
      if (done) break;
      
      const text = decoder.decode(value);
      const lines = text.split('\n');
      
      for (const line of lines) {
        if (line.startsWith('data: ')) {
          try {
            const jsonStr = line.slice(6);
            if (jsonStr.trim() === '{}') continue;
            
            const data = JSON.parse(jsonStr);
            if (data.result && !data.is_truncated) {
              explanation.value += data.result;
              const html = marked.parse(explanation.value);
              if (typeof html === 'string') {
                renderedExplanation.value = DOMPurify.sanitize(html);
              }
            }
            
            if (data.error) {
              throw new Error(data.error);
            }
          } catch (e) {
            if (e instanceof SyntaxError) {
              console.debug('跳过不完整的JSON数据:', line);
              continue;
            }
            throw e;
          }
        }
      }
    }
  } catch (err: any) {
    if (err.name === 'AbortError') {
      console.log('请求被中止');
      return;
    }
    error.value = err.message || '解析失败，请稍后重试';
    MessagePlugin.error(error.value);
  } finally {
    isLoading.value = false;
    streamController.value = null;
  }
};

onBeforeUnmount(() => {
  if (streamController.value) {
    streamController.value.abort();
  }
});
</script>

<style scoped>
.ai-content {
  padding: 16px;
  height: calc(100vh - 200px);
  overflow-y: auto;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  gap: 16px;
}

.error-message {
  margin-bottom: 16px;
}

.explanation {
  font-size: 14px;
  line-height: 1.6;
  color: var(--td-text-color-primary);
  white-space: pre-wrap;
  font-family: 'Fira Code', monospace;
}

.drawer-footer {
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
}

:deep(.t-loading__parent) {
  height: 100%;
}

/* 添加打字机效果 */
@keyframes typing {
  from { opacity: 0; }
  to { opacity: 1; }
}

.explanation:last-child {
  animation: typing 0.15s;
}

.initial-state {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  padding: 20px;
}

:deep(.t-empty) {
  padding: 32px;
}

:deep(.t-empty__description) {
  color: var(--td-text-color-secondary);
  font-size: 14px;
  margin-top: 16px;
}

:deep(.t-empty__image) {
  display: flex;
}

@import '../assets/styles/markdown.css';

.markdown-body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
  font-size: 17px;
  line-height: 1.6;
  word-wrap: break-word;
  padding: 16px;
}

.markdown-body pre {
  background-color: #f6f8fa;
  border-radius: 6px;
  padding: 16px;
  overflow: auto;
}

.markdown-body code {
  font-family: 'Fira Code', SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
  font-size: 12px;
  padding: 0.2em 0.4em;
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
}

.markdown-body pre code {
  padding: 0;
  background-color: transparent;
}
</style>