import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "ai-content" }
const _hoisted_2 = {
  key: 0,
  class: "loading-container"
}
const _hoisted_3 = {
  key: 2,
  class: "initial-state"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "drawer-footer" }

import { ref, onBeforeUnmount } from 'vue';
import { MessagePlugin } from 'tdesign-vue-next';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

// 配置 marked

export default /*@__PURE__*/_defineComponent({
  __name: 'AIExplainer',
  props: {
  visible: Boolean,
  code: String,
},
  emits: ['update:visible'],
  setup(__props, { emit: __emit }) {

marked.setOptions({
  gfm: true,
  breaks: true,
  pedantic: false
});

// 自定义处理空行
const renderer = {
  paragraph(text: any) {
    // 检查是否为空或只包含空白字符
    const content = text.text || text;
    if (typeof content === 'string' && /^\s*$/.test(content)) {
      return ''; // 直接返回空字符串，不生成空段落
    }
    return '<p>' + content + '</p>';
  }
};

marked.use({ renderer });

const props = __props;

const emit = __emit;
const isLoading = ref(false);
const error = ref('');
const explanation = ref('');
const renderedExplanation = ref('');
const streamController = ref<AbortController | null>(null);

const handleClose = () => {
  emit('update:visible', false);
};

const explainCode = async () => {
  if (!props.code?.trim()) {
    error.value = '没有可分析的代码';
    return;
  }

  isLoading.value = true;
  error.value = '';
  explanation.value = '';
  renderedExplanation.value = '';
  
  if (streamController.value) {
    streamController.value.abort();
  }
  
  streamController.value = new AbortController();
  
  try {
    const response = await fetch('https://aiapi.emoera.com/api.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code: props.code,
        action: 'explain',
        stream: true
      }),
      signal: streamController.value.signal
    });

    if (!response.ok) {
      throw new Error('网络请求失败');
    }

    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error('无法读取响应流');
    }

    const decoder = new TextDecoder();
    
    for (;;) {
      const { done, value } = await reader.read();
      if (done) break;
      
      const text = decoder.decode(value);
      const lines = text.split('\n');
      
      for (const line of lines) {
        if (line.startsWith('data: ')) {
          try {
            const jsonStr = line.slice(6);
            if (jsonStr.trim() === '{}') continue;
            
            const data = JSON.parse(jsonStr);
            if (data.result && !data.is_truncated) {
              explanation.value += data.result;
              const html = marked.parse(explanation.value);
              if (typeof html === 'string') {
                renderedExplanation.value = DOMPurify.sanitize(html);
              }
            }
            
            if (data.error) {
              throw new Error(data.error);
            }
          } catch (e) {
            if (e instanceof SyntaxError) {
              console.debug('跳过不完整的JSON数据:', line);
              continue;
            }
            throw e;
          }
        }
      }
    }
  } catch (err: any) {
    if (err.name === 'AbortError') {
      console.log('请求被中止');
      return;
    }
    error.value = err.message || '解析失败，请稍后重试';
    MessagePlugin.error(error.value);
  } finally {
    isLoading.value = false;
    streamController.value = null;
  }
};

onBeforeUnmount(() => {
  if (streamController.value) {
    streamController.value.abort();
  }
});

return (_ctx: any,_cache: any) => {
  const _component_t_loading = _resolveComponent("t-loading")!
  const _component_t_alert = _resolveComponent("t-alert")!
  const _component_t_empty = _resolveComponent("t-empty")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_drawer = _resolveComponent("t-drawer")!

  return (_openBlock(), _createBlock(_component_t_drawer, {
    visible: __props.visible,
    size: '50%',
    header: "AI代码分析",
    onClose: handleClose,
    closeOnOverlayClick: true,
    showOverlay: true,
    "size-draggable": true
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_t_button, {
          theme: "default",
          onClick: handleClose
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("关闭")
          ])),
          _: 1
        }),
        _createVNode(_component_t_button, {
          theme: "primary",
          onClick: explainCode,
          loading: isLoading.value,
          disabled: isLoading.value
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" 分析代码 ")
          ])),
          _: 1
        }, 8, ["loading", "disabled"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (isLoading.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_t_loading, {
                theme: "dots",
                size: "medium"
              }),
              _cache[0] || (_cache[0] = _createElementVNode("p", null, "AI正在分析代码...", -1))
            ]))
          : (error.value)
            ? (_openBlock(), _createBlock(_component_t_alert, {
                key: 1,
                theme: "error",
                message: error.value,
                class: "error-message"
              }, null, 8, ["message"]))
            : (!explanation.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_t_empty, {
                    description: "点击\"分析代码\"开始AI分析",
                    icon: "code-1"
                  })
                ]))
              : (_openBlock(), _createElementBlock("div", {
                  key: 3,
                  class: "explanation markdown-body",
                  innerHTML: renderedExplanation.value
                }, null, 8, _hoisted_4))
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})