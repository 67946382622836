import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "export-header" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "export-content" }

import { ref, nextTick } from 'vue';
import { ShareIcon } from 'tdesign-icons-vue-next';
import { MessagePlugin } from 'tdesign-vue-next';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import html2canvas from 'html2canvas';


export default /*@__PURE__*/_defineComponent({
  __name: 'ShareCode',
  props: {
    code: {},
    language: {}
  },
  setup(__props: any) {

const props = __props;

const exportRef = ref<HTMLElement | null>(null);
const isExporting = ref(false);

const shareAsImage = async () => {
  try {
    isExporting.value = true;
    await nextTick();
    
    if (!exportRef.value) {
      throw new Error('导出元素不存在');
    }

    // 先应用代码高亮
    const highlightedCode = hljs.highlight(props.code, {
      language: props.language,
      ignoreIllegals: true
    }).value;

    // 获取代码元素并更新内容
    const codeElement = exportRef.value.querySelector('.export-content code');
    if (codeElement) {
      codeElement.innerHTML = highlightedCode;
    }

    // 等待代码渲染完成
    await nextTick();

    // 获取实际代码宽度
    const actualWidth = codeElement?.scrollWidth || 0;
    
    // 计算所需的容器总宽度（代码宽度 + padding）
    const containerWidth = actualWidth + 80; // 80px 为左右 padding 的总和

    // 设置容器宽度
    exportRef.value.style.width = `${containerWidth}px`;

    const canvas = await html2canvas(exportRef.value, {
      scale: 2,
      backgroundColor: '#ffffff',
      logging: false,
      useCORS: true,
      onclone: (clonedDoc: Document) => {
        const clonedElement = clonedDoc.getElementById('code-export');
        if (clonedElement) {
          clonedElement.style.transform = 'none';
          clonedElement.style.left = '0';
          clonedElement.style.top = '0';
          clonedElement.style.width = `${containerWidth}px`;
        }
      }
    });

    const image = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.download = `code-${new Date().getTime()}.png`;
    link.href = image;
    link.click();

    MessagePlugin.success('导出成功！');
  } catch (error) {
    console.error('导出失败:', error);
    MessagePlugin.error('导出失败，请重试');
  } finally {
    isExporting.value = false;
    if (exportRef.value) {
      exportRef.value.style.width = '';
    }
  }
};

return (_ctx: any,_cache: any) => {
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_tooltip = _resolveComponent("t-tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_t_tooltip, { content: "导出为图片分享" }, {
      default: _withCtx(() => [
        _createVNode(_component_t_button, {
          variant: "outline",
          theme: "default",
          onClick: shareAsImage
        }, {
          icon: _withCtx(() => [
            _createVNode(_unref(ShareIcon))
          ]),
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createElementVNode("span", null, "分享", -1))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _withDirectives(_createElementVNode("div", {
      id: "code-export",
      class: "code-export",
      ref_key: "exportRef",
      ref: exportRef
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: require('@/assets/elogo.jpg'),
          alt: "logo",
          class: "export-logo"
        }, null, 8, _hoisted_2),
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "export-title" }, "E时代IDE", -1))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("pre", null, [
          _createElementVNode("code", {
            class: _normalizeClass(_ctx.language)
          }, _toDisplayString(_ctx.code), 3)
        ])
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "export-footer" }, [
        _createElementVNode("span", { class: "website" }, "ide.emoera.com")
      ], -1))
    ], 512), [
      [_vShow, isExporting.value]
    ])
  ], 64))
}
}

})