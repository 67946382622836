<!-- RunDetails.vue -->
<template>
  <t-drawer
    :visible="visible"
    :size="'50%'"
    :size-draggable="true"
    @close="handleClose"
    header="运行详情"
    :closeOnOverlayClick="true"
    :showOverlay="true"
  >
    <template #header>
      <div style="display: flex; align-items: center; gap: 16px;">
        <span>运行详情</span>
        <t-switch
          v-model="showErrorOnly"
          :label="['仅错误', '全部']"
        />
      </div>
    </template>

    <template v-if="runInfo && runInfo.length > 0">
      <div class="test-cases-details">
        <div 
          v-for="(result, index) in filteredResults" 
          :key="result.originalIndex || index" 
          class="test-case-detail"
        >
          <div class="test-case-header">
            <t-tag theme="default" variant="light" class="case-number">
              测试用例 #{{ (result.originalIndex || index) + 1 }}
            </t-tag>
            <t-tag 
              :theme="getStatusTheme(result.status)" 
              variant="light"
            >
              {{ getStatusText(result.status) }}
            </t-tag>
          </div>

          <div class="detail-section">
            <div class="detail-item">
              <div class="detail-label">运行时间：</div>
              <div class="detail-value">{{ (result.time / 1_000_000).toFixed(2) }}ms</div>
            </div>
            <div class="detail-item">
              <div class="detail-label">内存占用：</div>
              <div class="detail-value">{{ (result.memory / 1024).toFixed(2) }}KB</div>
            </div>
          </div>

          <div class="io-section">
            <div class="io-item">
              <div class="io-label">输入：</div>
              <t-textarea
                readonly
                :value="testCases[result.originalIndex || index]?.input ?? ''"
                :autosize="{ minRows: 2, maxRows: 4 }"
              />
            </div>
            
            <div class="io-item" v-if="testCases[result.originalIndex || index]?.expected">
              <div class="io-label">预期输出：</div>
              <t-textarea
                readonly
                :value="testCases[result.originalIndex || index]?.expected ?? ''"
                :autosize="{ minRows: 2, maxRows: 9 }"
              />
            </div>

            <div class="io-item">
              <div class="io-label">实际输出：</div>
              <t-textarea
                readonly
                :value="result.files?.stdout || ''"
                :autosize="{ minRows: 2, maxRows: 9 }"
              />
            </div>

            <div class="io-item error" v-if="result.files?.stderr">
              <div class="io-label">错误信息：</div>
              <t-textarea
                readonly
                :value="result.files.stderr"
                :autosize="{ minRows: 2, maxRows: 9 }"
              />
            </div>
          </div>

          <div class="result-section" v-if="testCases[result.originalIndex || index]?.expected && result.status === 'Accepted'">
            <t-alert
              :theme="isOutputMatch(result, result.originalIndex || index) ? 'success' : 'error'"
              :message="isOutputMatch(result, result.originalIndex || index) ? 'Accepted 输出结果正确' : 'Wrong Answer 输出结果错误'"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <t-empty description="请先运行代码" />
    </template>

    <template #footer>
      <div class="drawer-footer">
        <t-button theme="default" @click="handleClose">关闭</t-button>
        <t-tooltip content="拖动中间分界线可调整窗口大小">
          <t-icon name="help-circle" style="margin-left: 8px; color: var(--td-text-color-secondary);" />
        </t-tooltip>
      </div>
    </template>
  </t-drawer>
</template>

<script lang="ts" setup>
import { computed, defineProps, defineEmits, ref } from 'vue';

interface TestCase {
  input: string;
  expected?: string;
  output?: string;
  status?: string;
}

const props = defineProps({
  visible: { type: Boolean, default: false },
  runInfo: { type: Array, default: () => [] },
  testCases: { type: Array, default: () => [] }
});

const emit = defineEmits(['update:visible']);

const handleClose = () => {
  emit('update:visible', false);
};

const getStatusText = (status: string) => {
  const statusMap: Record<string, string> = {
    'Accepted': '运行成功',
    'Memory Limit Exceeded': '内存超限',
    'Time Limit Exceeded': '时间超限',
    'Output Limit Exceeded': '输出超限',
    'File Error': '文件错误',
    'NonzeroExitStatus': '运行异常',
    'Signalled': '进程被终止',
    'Internal Error': '内部错误'
  };
  return statusMap[status] || status;
};

const getStatusTheme = (status: string) => {
  return status === 'Accepted' ? 'success' : 'danger';
};

const isOutputMatch = (result: any, index: number) => {
  const testCase = props.testCases[index];
  if (!testCase?.expected) return true;
  const expectedOutput = testCase.expected.trim();
  const actualOutput = result.files?.stdout?.trim() || '';
  return expectedOutput === actualOutput;
};

const showErrorOnly = ref(false);

const filteredResults = computed(() => {
  if (!showErrorOnly.value) {
    return props.runInfo;
  }
  
  return props.runInfo.map((result: any, index: number) => {
    if (result.status !== 'Accepted' || 
        (result.status === 'Accepted' && !isOutputMatch(result, index))) {
      return { ...result, originalIndex: index };
    }
    return null;
  }).filter(item => item !== null);
});
</script>

<style scoped>
.test-cases-details {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
}

.test-case-detail {
  border: 1px solid #e5e6eb;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
}

.test-case-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.case-number {
  font-weight: 500;
}

.detail-section {
  display: flex;
  gap: 24px;
  margin-bottom: 16px;
  padding: 12px;
  background-color: #f5f5f5;
  border-radius: 6px;
}

.detail-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.detail-label {
  color: #666;
  font-size: 14px;
}

.detail-value {
  font-family: 'Fira Code', monospace;
  font-size: 14px;
  color: #333;
}

.io-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.io-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.io-label {
  font-size: 14px;
  color: #666;
  font-weight: 500;
}

.error .io-label {
  color: #e34d59;
}

.result-section {
  margin-top: 16px;
}

.drawer-footer {
  display: flex;
  align-items: center;
  padding: 0 16px;
}

:deep(.t-switch) {
  margin-left: auto;
}
</style>